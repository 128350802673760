<template>
  <div>
    <CRow v-if="candidates.length">
      <CCol
        lg="4"
        v-for="(
          {
            candidate,
            actions,
            sub_status,
            job_applied,
            status,
            reference_type_id,
            matched_comments,
            status_id,
            sub_status_id,
          },
          index
        ) in candidatesInfo"
        :key="index"
      >
        <CCard
          class="candidate-card"
          :id="`candidate-card-id-${candidate.candidate_uid}`"
          :class="{
            'candidate-active': isSelectedCandidate(candidate.candidate_uid),
          }"
        >
          <CCardBody class="position-relative">
            <div
              class="action-icon d-flex"
              v-if="
                [Role.customerAdmin, Role.customerRecruiter].includes(
                  currentUserRole
                )
              "
            >
              <div class="fav-action mx-2">
                <span
                  class="f-1_5 action position-relative multi-select-icon-div"
                  v-if="getMatched(index)"
                >
                  <i
                    v-show="getFavourite(index)"
                    class="text-primary fas fa-heart"
                    v-c-tooltip="`Click to Remove favourite Candidate`"
                    @click="removeFromFavourite(index)"
                  ></i>
                  <i
                    v-show="!getFavourite(index)"
                    class="far fa-heart"
                    v-c-tooltip="`Click to Add favourite Candidate`"
                    @click="addToFavourite(index)"
                  ></i>
                </span>
              </div>
              <CDropdown
                v-if="getActions(actions) && getActions(actions).length"
                :disabled="!getActions(actions).length"
                :class="{ 'disabled-menu': !getActions(actions).length }"
                placement="top-start"
              >
                <template #toggler>
                  <div class="px-2">
                    <i
                      class="fas fa-ellipsis-v"
                      name="three-dot-icon"
                      :id="`candidate-id-${candidate.candidate_uid}`"
                    ></i>
                  </div>
                </template>
                <a
                  class="dropdown-item cursor-action"
                  v-for="data in getActions(actions)"
                  :key="data.action_id"
                  @click="openPopup({ index, data })"
                  :name="`cand-match-action-id-${data.action_id}`"
                  :id="`candidate-id-${candidatesInfo[index].candidate_uid}`"
                  :class="{
                    'positive-action': checkPositiveAction(
                      data,
                      status,
                      sub_status
                    ),
                  }"
                >
                  {{ data.label }}
                </a>
              </CDropdown>
            </div>
            <div class="d-flex cv-icon">
              <CButton
                colour="primary"
                class="p-0 mx-1 d-flex"
                :disabled="!candidate.cv_document"
                @click="previewCV(candidate)"
                v-c-tooltip="{ content: 'View CV/Resume', appendToBody: true }"
              >
                <i class="fa-solid fa-eye m-0"></i>
              </CButton>
              <CButton
                colour="primary"
                class="btn-link p-0 mx-1 d-flex"
                :disabled="!candidate.cv_document"
                :href="generateLocalFileUrl(candidate.cv_document)"
                download
                target="_self"
                v-c-tooltip="{
                  content: 'Download CV/Resume',
                  appendToBody: true,
                }"
              >
                <i class="fa-solid fa-download m-0"></i>
              </CButton>
              <span
                class="d-flex align-items-end"
                v-if="[4, 3].includes(reference_type_id)"
              >
                <i
                  class="fa-solid fa-circle-info"
                  v-c-tooltip="{
                    content: `${matched_comments}`,
                    appendToBody: true,
                  }"
                ></i>
              </span>
            </div>
            <div
              class="content-job-title cursor-action f-12"
              @click="getCandidateDetails(index)"
            >
              <div class="image-wrapper">
                <img
                  :src="getOrganisationLogo(candidate)"
                  onerror="this.src='/img/user_default_pic.png'"
                  class="rounded-circle"
                  alt="DP"
                />
              </div>
              <span class="d-block"
                ><h5
                  name="candidate-name"
                  class="m-0"
                  :style="`color: ${getGenderColor(candidate.gender)}`"
                >
                  {{ toTitleCase(candidate.first_name) }}
                  {{ toTitleCase(candidate.surname) }}
                </h5></span
              >
              <span class="d-block">
                <span class="text-muted" name="candidate-type">{{
                  candidate.candidate_type.candidate_type
                }}</span>
              </span>

              <span class="d-block">
                <span class="text-muted" name="candidate-id">ID:</span>
                <!-- {{ candidate.candidate_uid }} -->
                {{ candidate.display_uid }}
              </span>
              <span
                name="candidate-speciality"
                class="d-block"
                v-if="candidate.speciality || candidate.qualifications"
                >{{ speciality(candidate) }}
              </span>
              <span class="d-block">{{ getCountry(candidate) }}</span>
            </div>
            <div class="candidate-status">
              <CBadge
                v-if="isAgencyBadge(candidate)"
                color="danger"
                class="font-weight-normal ml-1"
                >{{ `Agency - ${candidate.organisation_name}` }}</CBadge
              >
              <div v-else>
                <CBadge
                  v-for="org_name in candidate.shared_organisations"
                  :key="org_name.id"
                  color="wintergreen"
                  class="font-weight-normal ml-1 text-white"
                >
                <span v-if="org_name.firstly_registered">{{ org_name.name }}</span>
                  
                </CBadge>
              </div>
              <!-- <CBadge
                v-if="isOrgBadge(candidate) && !isAgencyBadge(candidate)"
                color="danger"
                class="font-weight-normal ml-1"
                >{{ candidate.organisation_name }}</CBadge
              > -->
              <CBadge
                color="success"
                class="font-weight-normal ml-1"
                v-if="job_applied"
              >
                <span>Applied</span></CBadge
              >
              <CBadge
                name="status-label-1"
                color="primary"
                class="font-weight-normal ml-1"
                v-else
                ><span>Talent Pool</span></CBadge
              >
              <CBadge
                name="status-label-2"
                :color="getStatusColor(status_id)"
                class="font-weight-normal ml-1 text-white"
                v-if="status && status['display_text']"
                ><span>{{ status["display_text"] }}</span></CBadge
              >
              <CBadge
                name="status-label-3"
                :color="getStatusColor(sub_status_id)"
                class="font-weight-normal ml-1 text-white"
                v-if="
                  sub_status &&
                  sub_status['display_text'] &&
                  !getFavourite(index)
                "
                ><span>{{ sub_status["display_text"] }}</span></CBadge
              >
            </div>
          </CCardBody>
        </CCard>
      </CCol>
    </CRow>
    <p v-else class="text-center text-gray h5">No Candidates Found</p>
  </div>
</template>
<script>
import moment from "moment";
import { 
  Role,
  getScope,
  getPositiveActionIds,
  generateFullUrlWithToken,
  generateLocalFileUrl,
  appendAccessToken,
 } from "@/helpers/helper";
import { mapGetters, mapActions } from "vuex";
import { BASE_URL } from "@/service_urls";

export default {
  props: {
    candidatesInfo: {
      type: Array,
      default: () => [],
    },
    selectedCandidate: {
      type: Object,
      default: () => null,
    },
    selectedTabIndex: {
      type: Number,
      default: () => null,
    },
  },
  data() {
    return {
      isMobile: false,
      currentUserRole: getScope(),
      Role,
      generateFullUrlWithToken,
      generateLocalFileUrl
    };
  },
  computed: {
    ...mapGetters([
      "getGenderColor",
      "getJobDetailsById",
      "getSubStatusOptions",

      // for pertemps
      "isCandidateSupplierFromAccessToken",
      "isJobSupplier",
      "isUKMainAgency",
      "getOrgIDFromAccessToken",
      "getIsParentUser",
    ]),
    candidates() {
      return (
        (this.candidatesInfo &&
          this.candidatesInfo.length &&
          this.candidatesInfo.map(({ candidate }) => candidate)) ||
        []
      );
    },
    isApplied() {
      return this.candidatesInfo.job_applied;
    },
    base_domain() {
      return BASE_URL.substring(0, BASE_URL.indexOf("/api"));
    },
    organisation_name() {
      return;
    },
  },
  methods: {
    ...mapActions(["initUpdateCandidateStatus"]),
    isAgencyBadge(candidate) {
      return (
        (this.isUKMainAgency &&
          candidate.agency_contact &&
          candidate.organisation_name &&
          this.currentUserRole !== this.Role.systemAdmin) ||
        false
      );
    },
    isOrgBadge(candidate) {
      return (
        (candidate.organisation_id != this.getOrgIDFromAccessToken &&
          this.getIsParentUser) ||
        false
      );
    },
    getCandidateDetails(index) {
      let candidateInfo = this.candidatesInfo[index];
      this.$emit("candidateSelect", candidateInfo);
    },
    getCountry(data) {
      return data.location?.country?.country_name || null;
    },
    getOrganisationType(data) {
      return data.organisation?.organisation_type?.organisation_type || "--";
    },
    getOrganisationLogo(data) {
      return generateFullUrlWithToken(data.profile_picture);
    },
    toTitleCase(str) {
      return str.replace(/\w\S*/g, function (txt) {
        return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
      });
    },
    speciality(data) {
      let highestQualification = null;
      data.qualifications &&
        data.qualifications.length &&
        data.qualifications.some((e) => {
          if (e.main) {
            highestQualification = e;
          }
        });
      let speciality = data.speciality?.speciality || null;
      let sub_speciality = data.sub_speciality?.sub_speciality || null;
      highestQualification = highestQualification
        ? highestQualification.qualification.qualification_with_acronym
        : null;
      if (highestQualification) {
        if (sub_speciality) {
          return speciality + "|" + sub_speciality + "|" + highestQualification;
        } else if (speciality) {
          return speciality + "|" + highestQualification;
        } else {
          return highestQualification;
        }
      } else {
        if (sub_speciality) {
          return speciality + "|" + sub_speciality;
        } else if (speciality) {
          return speciality;
        } else {
          return null;
        }
      }
    },
    isSelectedCandidate(candidateID) {
      if (candidateID && this.selectedCandidate)
        return candidateID === this.selectedCandidate.candidate_uid;
      return false;
    },
    subStatus(sub_status_id) {
      return sub_status_id && this.getSubStatusOptions
        ? this.getSubStatusOptions.find(({ code }) => code === sub_status_id)
            ?.label
        : null;
    },
    openPopup({ index, data }) {
      const candidateDetails = this.candidatesInfo[index];
      let payload = { candidateDetails, selectedAction: data };
      this.$emit("actionModalEvent", payload);
    },
    getActions(actions) {
      return (
        (actions &&
          actions.map((val) => {
            return {
              label: val?.name,
              action_id: val?.id,
              directAction: val?.direct_action,
              status: val?.status,
              filter_id: val?.filter,
              tab_id: val?.tab,
              reason_id: val?.reason,
            };
          })) ||
        []
      );
    },
    getMatched(index) {
      const candidateInfo = this.candidatesInfo[index];
      return candidateInfo?.status_id === 42;
    },
    getFavourite(index) {
      const candidateInfo = this.candidatesInfo[index];
      return (
        candidateInfo?.status_id === 42 && candidateInfo?.sub_status_id === 70
      );
    },
    addToFavourite(index) {
      const candidateDetails = this.candidatesInfo[index];
      const selectedAction = {
        directAction: true,
        status: { update_status: 42, update_sub_status: 70 },
      };
      let payload = { candidateDetails, selectedAction };
      this.$emit("actionModalEvent", payload);
    },
    removeFromFavourite(index) {
      const candidateDetails = this.candidatesInfo[index];
      const selectedAction = {
        directAction: true,
        status: { update_status: 42, update_sub_status: null },
      };
      let payload = { candidateDetails, selectedAction };
      this.$emit("actionModalEvent", payload);
    },
    checkPositiveAction(action, status, sub_status) {
      const { action_id } = action;
      const status_id = status?.status_id;
      const sub_status_id = sub_status?.status_id;
      const actionIds =
        getPositiveActionIds({ status_id, sub_status_id }) || [];
      return actionIds.includes(action_id);
    },
    showPreview(candidate) {
      const { link_to_cv_doc } = candidate;
      //  const _baseUrl = BASE_URL;
      //  const _base_domain = _baseUrl.substring(0, _baseUrl.indexOf("/api"));
      // const _base_domain = "https://" + window.location.host;
      return generateFullUrlWithToken(link_to_cv_doc);
    },
    previewCV(candidate) {
      candidate.link_to_document = this.showPreview(candidate);
      const { cv_ext: document_ext, link_to_document } = candidate;
      this.$emit("previewCV", {
        link_to_doc: link_to_document,
        document_ext,
      });
    },
    getStatusColor(statusId) {
      switch (statusId) {
        case 39:
          return "success";
        case 42:
          return "matched";
        case 68:
          return "info";
        case 54:
          return "interview-status";
        case 62:
          return "onboarding-status";
        case 52:
          return "submitted-status";
        case 58:
          return "low";
        case 69:
          return "approved-onboarding";
        case 57:
          return "interview-completed";
        case 55:
          return "warning";
        case 53:
          return "awaiting-feedback";
        case 48:
          return "withdrawn";
        case 77:
          return "possible";
        case 41:
          return "manual";
        case 76:
          return "definite";
        case 71:
          return "inreview";
        case 59:
          return "awaiting-reponse";
        case 63:
          return "joined";
        case 47:
          return "disapproved";
        case 67:
          return "interview";
        case 49:
          return "reserve";
        case 51:
          return "interview-requested";
        case 50:
          return "interview-approved";
        case 56:
          return "rescheduled";
        case 60:
          return "success";
        case 86:
          return "low-priority";
        case 85:
          return "medium-priority";
        case 84:
          return "high-priority";
        default:
          return "info";
      }
    },
  },
  mounted() {
    let isMobile = window.matchMedia(
      "only screen and (max-width: 760px)"
    ).matches;
    this.isMobile = isMobile;
  },
  filters: {
    OrganisationName(data) {
      return data?.map(({ name }) => name).join(", ");
    },
  },
};
</script>
<style lang="scss" scoped>
.content-job-title {
  text-align: center;
  .image-wrapper img {
    width: 50px;
  }
}
.cursor-action {
  cursor: pointer;
}
.candidate-card:hover {
  background-color: #fdf1f64d;
}
.candidate-card > .card-body {
  padding-bottom: 30px;
}
.candidate-card.candidate-active {
  background-color: #fdf1f6;
  box-shadow: 0 4px 8px 0 rgba(253, 241, 246, 0.15),
    0 6px 20px 0 rgba(253, 241, 246, 0.14);
}
.action-icon {
  position: absolute;
  top: 1.24em;
  right: 1.4em;
  z-index: 1;
  cursor: pointer;
}
.cv-icon {
  position: absolute;
  top: 1.24em;
  left: 1.4em;
  z-index: 1;
  .btn {
    color: #3c4b56 !important;
  }
}
.candidate-status {
  position: absolute;
  bottom: 1.24em;
  // left: 1.4em;
  // z-index: 1;
}

.card {
  height: 280px;
}
@media (min-width: 992px) {
  .col-lg-4 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 33.33333%;
    flex: 0 0 33.33333%;
    // border: 1px solid #eee;
    max-width: 30.33333%;
    margin: 0.5rem;
  }
}
.disabled-menu {
  color: #a9a9a9;
  cursor: default;
}
.positive-action {
  background-color: lightgreen;
}
</style>
